/*  ==========================================================================
    LINKBOXES
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__linkboxes--color: $clr__typo--secondary;
$module-clr__linkboxes--bg: $clr__ci--primary;

/*
 * MODULE
 */

.bn-linkboxes_special {
  .bn-linkboxes__first_line,
  .bn-linkboxes__second_line {
    display: block;
    text-align: center;
    pointer-events: none;
    line-height: 0.3;
    z-index: 43;
    position: relative;
    letter-spacing: 8px;
    > span {
      font-size: 12.4em;
      font-family: $typo__ff--tertiary;
      color: #B4AA98;
      font-weight: $typo__fw--bold;
    }
  }
  .bn-linkboxes__second_line {
    line-height: 0;
  }
  .bn-linkboxes__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 50px;
    .bn-linkboxes__item {
      display: inline-block;
      position: relative;
      width: calc(25% - 30px);
      margin-bottom: 20px;
      > div {
        transform: skewX(-20deg);
        overflow: hidden;
      }
      .bn-linkboxes__image {
        display: block;
        transform: skewX(20deg)scale(1.3);
        > div {
          overflow: hidden;
        }
        img {
          @include img--responsive();
          transform: translateX(-12%);
          width: auto;
        }
      }
      .bn-linkboxes__titles {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 100%;
        background: rgba($clr__ci--primary, 0.7);
        transition: background .2s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding: 30px 30px 50px;
        .bn-linkboxes__title {
          transform: skewX(20deg);
          span {
            font-size: 2em;
            color: $clr__typo--secondary;
          }
        }
        .bn-linkboxes__subtitle {
          transform: skewX(20deg);
          span {
            font-size: 11.1em;
            color: $clr__typo--secondary;
            text-transform: uppercase;
            font-family: $typo__ff--secondary;
          }
        }
      }
      &:hover {
        .bn-linkboxes__titles {
          background: rgba($clr__ci--primary, 0.2);
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

@media #{$mq__desktop--small} {
  .bn-linkboxes_special {
    .bn-linkboxes__list {
      .bn-linkboxes__item {
        width: calc(25% - 20px);
        .bn-linkboxes__image {
          transform: skewX(20deg) scale(1.4);
          img {
            max-height: 240px;
          }
        }
      }
    }
  }
}
// laptop
@media #{$mq__laptop} {
  .bn-linkboxes_special {
    .bn-linkboxes__list {
      .bn-linkboxes__item {
        .bn-linkboxes__titles {
          .bn-linkboxes__title {
            span {
              font-size: 1.8em;
            }
          }
          .bn-linkboxes__subtitle {
            span {
              font-size: 10em;
            }
          }
        }
        .bn-linkboxes__image {
          img {
            max-height: 200px;
          }
        }
      }
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-linkboxes_special {
    .bn-linkboxes__list {
      padding: 0 10px;
      .bn-linkboxes__item {
        width: calc(50% - 10px);
        .bn-linkboxes__image {
          img {
            max-height: none;
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .bn-linkboxes_special {
    .bn-linkboxes__first_line,
    .bn-linkboxes__second_line {
      > span {
        font-size: 6.5em;
      }
    }
    .bn-linkboxes__list {
      .bn-linkboxes__item {
        width: calc(50% - 10px);
        .bn-linkboxes__image {
          img {
            max-height: 140px;
            transform: translateX(8px);
          }
        }
        .bn-linkboxes__titles {
          padding: 10px;
          .bn-linkboxes__title {
            span {
              font-size: 1.4em;
            }
          }
          .bn-linkboxes__subtitle {
            span {
              font-size: 5.6em;
            }
          }
        }
      }
    }
  }
}
