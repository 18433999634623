/*  ==========================================================================
    REQUEST
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__request--bg: $clr__ci--primary;

/*
 * MODULE
 */

.bn-request {
  .bn-request__holder {
    //background-color: rgba($module-clr__request--bg,0.1); Optional
    .bn_request_variation__vertical {
      @include request--v1();
    }
    .bn-form__block--offer-lastminute-Preview .bn-price {
      display: none!important;
    }
  }
}
//Disable Offerpreview on Offerpoup & Offerdetail
.popupForm,
.detailForm{
  .ractive--offer-lastminute-Template{
    display: none;
  }
}

//Set White as Standard Background
.bn-form-request .bn-form__grid .bn-form__block{
  background-color: #fff;
}

//Flatpicker Index
body .bn_request_flatpickr{
  z-index: 200 !important;
}
.bn_request_validation__popup .bn-form-error {
  background: $clr__white!important;
}

body {
  #bn_request .bn-form__button-container .bn-form__button {
    color: $clr__white!important;
  }
  #bn_request .bn-form__button-container .bn-form__button:hover {
    color: $clr__white!important;
  }
  #bn_request .bn-form__button-container .bn-form__button:hover {
    border-color: rgba(185,134,39,.7)!important;
  }
  #bn_request .bn-form__button-container .bn-form__button:hover {
    background: rgba(185,134,39,.7)!important;
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
