/*  ==========================================================================
    OFFERS
    ========================================================================== */

// filter
@mixin offers--filter($default,$accent) {
  margin-bottom: 40px;
  .bn-offers-filter__controls {
    display: block;
    .bn-offers-filter__divider {
      width: 100%;
      display: block;
      margin: 20px 0 10px;
      border-top: 1px solid $clr__ci--secondary;
    }
    .bn-offers-filter__period,
    .bn-offers-filter__categories {
      width: 100%;
      color: $default;
      font-size: 1.6em;
      text-transform: uppercase;
      .bn-offers-filter__title {
        margin-bottom: 10px;
        white-space: nowrap;
        width: 15%;
        display: inline-block;
        font-family: $typo__ff--secondary;
        font-size: 2em;
        vertical-align: middle;
      }
      .bn-offers-filter__options {
        width: 75%;
        padding-right: 5%;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .bn-offers-filter__option {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        background-color: $clr__ci--secondary;
        color: $clr__typo--primary;
        cursor: pointer;
        flex: 1 0 21%;
        margin-right: 15px;
        margin-top: 15px;
        label {
          white-space: nowrap;
          text-align: center;
          width: 100%;
          cursor: pointer;
          padding: 10px;
        }
        input {
          background-color: $clr__white;
          border: 1px solid $default;
          border-radius: 20px;
          color: $accent;
          cursor: pointer;
          text-align: center;
          width: 20px;
          &:focus {
            border-color: $accent;
          }
        }
        label,
        input {
          line-height: 30px;
        }
        .bn-offers-filter__option-overlay {
          cursor: pointer;
          display: none;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
        }
        &:hover {
          background: #D0D0D0;
          color: $clr__white;
        }
        &.bn-offers-filter__option--all {
          width: 10%;
          display: inline-flex;
          margin-right: 0;
          background: $clr__ci--custom;
          color: $clr__white;
          &:hover {
            background: $clr__ci--secondary;
            color: $clr__typo--primary;
          }
        }
        &.bn-offers-filter__option-active {
          background: $clr__ci--primary;
          color: $clr__white;
        }
      }
    }
    .bn-offers-filter__period {
      .bn-offers-filter__options {
        .bn-offers-filter__option {
          flex: 1;
          text-transform: none;
        }
      }
    }
    //.bn-offers-filter__categories {
    //  .bn-offers-filter__options {
    //  }
    //  .bn-offers-filter__option {
    //    flex: 1 0 21%;
    //    margin-right: 15px;
    //    margin-top: 15px;
    //    input {
    //      text-align: center;
    //      width: 20px;
    //    }
    //  }
    //}
  }
  .bn-offers-filter__button {
    margin-top: 20px;
    .bn-offers-filter__submit {
      @include button--default();
    }
  }
  /*
   * RESPONSIVE BEHAVIOR
   */

  // tablet
  @media #{$mq__laptop} {
    .bn-offers-filter__controls {
      .bn-offers-filter__period {
        .bn-offers-filter__options {
          .bn-offers-filter__option {
            flex: 1 0 13%;
          }
        }
      }
    }
  }
  // tablet
  @media #{$mq__tablet} {
    .bn-offers-filter__controls {
      display: block;
      .bn-offers-filter__period,
      .bn-offers-filter__categories {
        font-size: 1.4em;
        width: 100% !important;
        .bn-offers-filter__title {
          display: block;
        }
        .bn-offers-filter__options {
          width: 85%;
          .bn-offers-filter__option {
            .bn-offers-filter__option-overlay {
              display: none !important;
            }
            &.bn-offers-filter__option--all {
              width: 15%;
            }
          }
        }
        .bn-offers-filter__option {
          &.bn-offers-filter__option--all {
            width: 15%;
          }
        }
      }
      .bn-offers-filter__categories {
        margin-top: 20px;
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    margin-bottom: 20px;
    .bn-offers-filter__controls {
      .bn-offers-filter__period,
      .bn-offers-filter__categories {
        font-size: 1.2em;
      }
      .bn-offers-filter__categories {
        .bn-offers-filter__options {
          .bn-offers-filter__option {
            width: 50%;
            flex: 1 0 33%;
          }
        }
      }
      .bn-offers-filter__period {
        .bn-offers-filter__options {
          .bn-offers-filter__option {
            flex: 1 0 21%;
          }
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    .bn-offers-filter__controls {
      .bn-offers-filter__categories {
        font-size: 1.4em;
        .bn-offers-filter__options {
          width: 100%;
          padding-right: 0;
          .bn-offers-filter__option {
            width: 100%;
          }
        }
        .bn-offers-filter__option.bn-offers-filter__option--all {
          width: 100%;
        }
      }
      .bn-offers-filter__period {
        font-size: 1.4em;
        .bn-offers-filter__options {
          width: 100%;
          padding-right: 0;
        }
        .bn-offers-filter__option.bn-offers-filter__option--all {
          width: 100%;
        }
      }
    }
  }
}

// detail
@mixin offers--detail($accent,$bg-price,$description,$headline,$suheadline,$typo-price) {
  .bn-offer-box {
    .bn-button.bn-toggleimage{
      @include button--default();
      height: 40px;
      line-height: 37px;
      margin-bottom: 10px;
      border-radius: 25px;
    }
    .bn-offer-box__images {
      overflow: hidden;
      position: relative;
      &.bn-hidden {
        display: none;
      }
      .bn-offer-box__slider-container {
        @include aspect-ratio(16,9);
        background-color: $clr__black;
        overflow: hidden;
        position: relative;
        .bn-offer-box__slider {
          left: 0;
          overflow: hidden;
          position: absolute;
          top: 0;
          width: 100%;
          .bn-offer-box__image {
            overflow: hidden;
            img {
              @include img--responsive();
            }
          }
        }
        .bn-offer-box__arrows {
          @include slider--arrows($clr__white);
          left: 0;
          padding: 0 20px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          z-index: 5;
        }
        .bn-offer-box__dots {
          @include slider--dots($clr__white,6);
          bottom: 20px;
          left: 20px;
          position: absolute;
          width: 100%;
          z-index: 5;
        }
      }
      .bn-offer-box__price {
        background-color: $bg-price;
        color: $typo-price;
        height: 150px;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        min-width: 150px;
        z-index: 5;
        > div {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          padding: 10px;
          position: relative;
          text-align: center;
        }
        .bn-offer-box__amount-addition {
          font-size: 1.6em;
        }
        .bn-offer-box__amount {
          font-size: 2.5em;
        }
      }
    }
    .bn-offer-box__content {
      .bn-offer-box__request-button {
        @include button--cta();
        border-radius: 0;
        font-size: 2em;
        margin-top: 25px;
        padding: 0;
        a {
          color: $clr__white;
          display: block;
          padding: 0 20px;
          &:visited {
            color: $clr__white;
          }
        }
        &:hover {
          a {
            color: $clr__cta--primary;
          }
        }
      }
      .bn-offer-box__seasondatas,
      .bn-offer-box__nights {
        font-size: 2.6em;
      }
      .bn-offer-box__seasondatas {
        margin-top: 20px;
      }
      .bn-offer-box__nights {
        margin-top: 10px;
      }
      .bn-offer-box__title {
        @include typo--headline($headline);
        margin-top: 10px;

      }
      .bn-offer-box__subtitle {
        @include typo--subheadline($suheadline);
        font-family: $typo__ff--primary;
        font-size: 1.9em;
        font-weight: $typo__fw--bold;
        margin-top: 10px;
        text-transform: none;
      }
      .bn-offer-box__description {
        @include typo--rte($description,$accent);
        font-size: $typo__fs--text;
        margin-top: 20px;
        * {
          font-size: 1.0em !important;
        }
        .special_table_wrap {
          overflow-x: auto;
        }
        //@include typo--table-wrap($description,$accent);
      }
      .bn-offer-box__rates,
      .bn-offer-box__inquiry,
      .bn-offer-box__buttons {
        margin-top: 40px;
      }
      .bn-offer-box__rates {
        @include typo--rte($description,$accent);
        .bn-table-wrap {
          .bn-table {
            tr {
              td {
                width: 25%;
                &:first-child {
                  width: 75%;
                }
              }
            }
          }
        }
      }
      .bn-offer-box__inquiry {
        .bn_request_variation__vertical {
          @include request--v1();
        }
      }
      .bn-offer-box__buttons {
        display: flex;
        flex-wrap: wrap;
        > div {
          align-items: flex-end;
          display: flex;
          justify-content: space-between;
          max-width: 33.33%;
          padding-left: 40px;
          text-align: right;
          width: 100%;
          &:first-child {
            padding-left: 0;
            text-align: left;
          }
          &:nth-child(2),
          &:nth-child(3){
            display:none;
          }
          .bn-button {
            &.bn-button--back {
              color: $accent;
              font-size: 1.4em;
              &:visited {
                color: $accent;
              }
              &:hover {
                color: rgba($accent, 0.5);
              }
              .bn-icon {
                margin-right: 5px;
              }
            }
            &.bn-button--inquire,
            &.bn-button--book {
              min-width: 0 !important;
              width: 100%;
            }
            &.bn-button--inquire {
              @include button--cta-inverted();
            }
            &.bn-button--book {
              @include button--cta();
            }
          }
        }
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // tablet
  @media #{$mq__tablet} {
    .bn-offer-box {
      .bn-offer-box__images {
        .bn-offer-box__price {
          height: 125px;
          min-width: 125px;
          right: 20px;
          top: 20px;
          .bn-offer-box__amount {
            font-size: 2.0em;
          }
        }
      }
      .bn-offer-box__content {
        .bn-offer-box__buttons {
          > div {
            padding-left: 20px;
          }
        }
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    .bn-offer-box {
      .bn-offer-box__images {
        .bn-offer-box__price {
          height: auto;
          min-width: 0;
          position: static;
          right: auto;
          top: auto;
          > div {
            display: block;
            height: auto;
          }
          .bn-offer-box__amount-addition,
          .bn-offer-box__amount {
            display: inline-block;
          }
          .bn-offer-box__amount-addition {
            margin: 0 5px;
          }
        }
      }
      .bn-offer-box__content {
        .bn-offer-box__seasondatas,
        .bn-offer-box__nights {
          font-size: 2.2em;
        }
        .bn-offer-box__buttons {
          > div {
            margin-bottom: 10px;
            max-width: 100%;
            padding-left: 0;
            &:first-child {
              margin-bottom: 0;
              order: 3;
            }
          }
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    .bn-offer-box {
      .bn-offer-box__images {
        bn-offer-box__slider-container {
          .bn-offer-box__arrows {
            display: none;
          }
          .bn-offer-box__dots {
            bottom: 10px;
            left: 10px;
          }
        }
      }
    }
  }
  // 300px max width
  @media #{$mq__max-w--300} {
    .bn-offer-box {
      .bn-offer-box__images {
        .bn-offer-box__slider-container {
          .bn-offer-box__dots {
            display: none;
          }
        }
      }
    }
  }
}
