/*  ==========================================================================
    SIDEBAR
    ========================================================================== */

@mixin header--sidebar() {
  background-color: $clr__ci--primary;
  display: none;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: $unit__wdt--header-v3-sidebar-desktop-large;
  z-index: $unit__zi--header-v3-sidebar;
  a {
    color: rgba($clr__typo--secondary, 0.5);
    &:visited {
      color: rgba($clr__typo--secondary, 0.5);
    }
    &:hover {
      color: $clr__typo--secondary;
    }
  }
  .bn-sidebar__top,
  .bn-sidebar__center,
  .bn-sidebar__bottom {
    display: flex;
    width: 100%;
  }
  .bn-sidebar__top,
  .bn-sidebar__bottom {
    flex: none;
  }
  .bn-sidebar__top {
    padding-bottom: 20px;
  }
  .bn-sidebar__center {
    @include scrollbar($clr__scrollbar,5px);
    flex: 1;
    overflow-y: auto;
  }
  .bn-sidebar__bottom {
    padding-top: 20px;
  }
  // close
  .bn-close {
    color: rgba($clr__typo--secondary, 0.5);
    cursor: pointer;
    display: inline-block;
    pointer-events: initial;
    .bn-icon {
      font-size: 3.5em;
      line-height: 50px;
    }
    &:hover {
      color: $clr__typo--secondary;
    }
  }
  // main logo
  .bn-main-logo {
    &.bn-main-logo--aside {
      margin-top: 40px;
      img {
        @include img--rendering-crisp-edges();
        max-width: 100%;
      }
      .bn-main-logo__image {
        &.bn-main-logo__image--big {
          display: none;
        }
        &.bn-main-logo__image--small {
          display: inline-block;
          img {
            height: 60px;
          }
        }
        &.bn-main-logo__image--mobile {
          display: none;
        }
      }
    }
  }
  // main menu
  .bn-main-menu {
    .bn-main-menu__lvl--0 {
      padding-left: 0;
      list-style-type: none;
      .bn-main-menu__item {
        position: relative;
        &:before {
          background-color: $clr__white;
          content: '';
          display: none;
          left: -40px;
          height: 100%;
          position: absolute;
          top: 0;
          width: calc(100% + 40px);
        }
        > .bn-main-menu__link {
          position: relative;
          z-index: 5;
        }
        &:hover {
          &:before {
            display: block;
          }
          > .bn-main-menu__link {
            color: $clr__ci--primary !important;
          }
        }
        &.bn-main-menu__item--act,
        &.bn-main-menu__item--cur {
          > .bn-main-menu__link {
            color: $clr__typo--secondary;
          }
        }
      }
      > .bn-main-menu__item {
        > .bn-main-menu__link {
          display: block;
          padding: 20px 40px 20px 0;
          > .bn-main-menu__titles {
            .bn-main-menu__title,
            .bn-main-menu__subtitle {
              text-transform: uppercase;
            }
            .bn-main-menu__title {
              font-size: 2.5em;
            }
            .bn-main-menu__subtitle {
              font-size: 2.0em;
            }
          }
          .bn-sub-menu-trigger {
            display: none;
          }
        }
        .bn-main-menu__sub-menu {
          > .bn-main-menu__arrow {
            display: none;
          }
          .bn-main-menu__lvl-wrap {
            display: none;
            height: 100vh;
            position: fixed;
            top: 0;
            width: $unit__wdt--header-v3-sub-lvl-wrap-desktop-large;
            z-index: $unit__zi--header-v3-sub-menu-wrap;
            &:before {
              background-color: $clr__white;
              content: '';
              display: block;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              width: calc(100% - 20px);
            }
            > div {
              height: 100%;
              position: relative;
              margin-left: 20px;
              width: calc(100% - 20px);
              z-index: 5;
            }
            .bn-main-menu__lvl {
              @include scrollbar($clr__scrollbar,5px);
              list-style-type: none;
              max-height: 100%;
              overflow-y: auto;
              padding: 30px 0 30px 40px;
              .bn-main-menu__item {
                .bn-main-menu__link {
                  display: block;
                  font-size: 2.0em;
                  padding: 10px 40px 10px 0;
                }
              }
            }
            &.bn-main-menu__lvl-wrap--1 {
              left: calc(#{$unit__wdt--header-v3-sidebar-desktop-large} - 20px);
              > div {
                background-color: rgba($clr__ci--primary, 0.9);
              }
            }
            &.bn-main-menu__lvl-wrap--2 {
              left: calc(#{$unit__wdt--header-v3-sidebar-desktop-large} + #{$unit__wdt--header-v3-sub-lvl-wrap-desktop-large} - 40px);
              > div {
                background-color: rgba($clr__ci--primary, 0.8);
              }
            }
          }
        }
        &:hover {
          > .bn-main-menu__link {
            color: $clr__typo--secondary;
          }
          > .bn-main-menu__sub-menu {
            > .bn-main-menu__lvl-wrap {
              display: block;
              .bn-main-menu__lvl {
                .bn-main-menu__item {
                  &:hover {
                    > .bn-main-menu__link {
                      color: $clr__typo--secondary;
                    }
                    > .bn-main-menu__lvl-wrap {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // top references
  .bn-top-references {
    &.bn-top-references--aside {
      font-size: 2.5em;
      margin-bottom: 20px;
      .bn-top-references__item {
        margin-left: 10px;
        &:first-child {
          margin-left: 0;
        }
        &.bn-top-references__item--trigger {
          display: none;
        }
      }
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--aside {
      ul {
        @include clearfix();
        font-size: 1.6em;
        list-style-type: none;
        padding-left: 0;
        li {
          float: left;
          &:before {
            content: '|';
          }
          a {
            padding: 0 5px;
          }
          &.bn-lang-menu__item--act {
            a {
              color: $clr__typo--secondary;
              font-weight: $typo__fw--bold;
            }
          }
          &:first-child {
            border-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  // tel
  .bn-tel {
    &.bn-tel--aside {
      font-size: 2.0em;
      margin-top: 20px;
    }
  }
  // call to action
  .bn-call-to-action {
    &.bn-call-to-action--aside {
      display: none;
      margin-top: 20px;
      .bn-button {
        @include button--cta();
        max-width: 250px;
        min-width: 0;
        width: 100%;
      }
    }
  }
  // open
  &.bn-sidebar--open {
    display: flex;
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // desktop medium
  @media #{$mq__desktop--medium} {
    width: $unit__wdt--header-v3-sidebar;
    // main menu
    .bn-main-menu {
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          .bn-main-menu__sub-menu {
            .bn-main-menu__lvl-wrap {
              width: $unit__wdt--header-v3-sub-lvl-wrap;
              &.bn-main-menu__lvl-wrap--1 {
                left: calc(#{$unit__wdt--header-v3-sidebar} - 20px);
              }
              &.bn-main-menu__lvl-wrap--2 {
                left: calc(#{$unit__wdt--header-v3-sidebar} + #{$unit__wdt--header-v3-sub-lvl-wrap} - 40px);
              }
            }
          }
        }
      }
    }
  }
  // laptop
  @media #{$mq__laptop} {
    .bn-sidebar__top {
      padding: 20px 20px 10px 20px;
    }
    .bn-sidebar__center {
      padding-left: 20px;
    }
    .bn-sidebar__bottom {
      padding: 10px 20px 20px 20px;
    }
    // main logo
    .bn-main-logo {
      &.bn-main-logo--aside {
        margin-top: 20px;
      }
    }
    // main menu
    .bn-main-menu {
      .bn-main-menu__item {
        &:before {
          left: -20px;
          width: calc(100% + 20px);
        }
      }
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          > .bn-main-menu__link {
            padding: 10px 20px 10px 0;
          }
          .bn-main-menu__sub-menu {
            .bn-main-menu__lvl-wrap {
              .bn-main-menu__lvl {
                padding: 10px 0 10px 20px;
                .bn-main-menu__item {
                  .bn-main-menu__link {
                    padding: 10px 20px 10px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}