/*  ==========================================================================
    PROGRESS BAR
    Needs "pace.js" (https://github.com/HubSpot/PACE)
    ========================================================================== */

@mixin progress-bar($clr) {

  body > {

    /*
     * prefabricated style (http://github.hubspot.com/pace/docs/welcome/)
     */

    .pace {
      -webkit-pointer-events: none;
      pointer-events: none;

      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
    }

    .pace-inactive {
      display: none;
    }

    .pace .pace-progress {
      background: $clr;
      position: fixed;
      z-index: 2000;
      top: 0;
      right: 100%;
      width: 100%;
      height: 4px;
    }

    /*
     * custom
     */

    .pace {
      z-index: 100;
    }

    html {
      &.lt-ie12 {
        .pace {
          display: none !important;
        }
      }
    }

  }

}