/*  ==========================================================================
    MEDIA QUERIES
    Collection of media queries.

    INFO:
    - don't change or delete any "main steps" or "fixed intermediate steps"
    - add your own media queries here: "intermediate steps -> custom"
    - try not to use too many custom media queries
    ========================================================================== */

/*
 * main steps
 */

// horizontal and vertical
$mq__full-screen--on: "(min-height: 700px) and (min-width: 1025px)";
$mq__full-screen--off: "(max-height: 699px) and (min-width: 1025px)";

// horizontal
$mq__desktop--large: "(min-width: 1921px)";
$mq__desktop--medium: "(max-width: 1920px)";
$mq__desktop--small: "(max-width: 1600px)";
$mq__laptop: "(max-width: 1366px)";
$mq__tablet: "(max-width: 1024px)";
$mq__phablet: "(max-width: 768px)";
$mq__phone: "(max-width: 480px)";
$mq__phone--small: "(max-width: 380px)";

/*
 * intermediate steps
 */

// fixed
$mq__max-h--900: "(max-height: 900px)";
$mq__max-h--800: "(max-height: 800px)";
$mq__max-h--700: "(max-height: 700px)";
$mq__max-h--600: "(max-height: 600px)";
$mq__max-h--370: "(max-height: 370px)";
$mq__max-w--300: "(max-width: 300px)";

// custom
