/*  ==========================================================================
    ADDITIONALS LIST VIEW
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__additionals-list-view--border: $clr__ci--primary;
$module-clr__additionals-list-view--headline: $clr__typo--primary;
$module-clr__additionals-list-view--text: $clr__typo--primary;
$module-clr__additionals-list-view--accent: $clr__ci--primary;

/*
 * MODULE
 */

.bn-additionals-list-view {
  > div.bn-grid__row {
    padding-top: 20px!important;
  }
  .bn-additionals-list-view__holder {
    .bn-additional-slide-box {
      .bn-additional-slide-box__head {
        border-bottom: 1px solid $module-clr__additionals-list-view--border;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        position: relative;
        .bn-additional-slide-box__headline,
        .bn-additional-slide-box__duration,
        .bn-additional-slide-box__amount {
          align-items: center;
          display: flex;
        }
        .bn-additional-slide-box__headline {
          @include typo--subheadline($module-clr__additionals-list-view--headline);
          font-weight: 700;
          text-transform: none;
          width: 50%;
          font-size: 1.8em !important;
        }
        .bn-additional-slide-box__duration,
        .bn-additional-slide-box__amount {
          color: $module-clr__additionals-list-view--text;
          font-size: 1.8em;
          padding-left: 20px;
          width: 25%;
          .bn-icon {
            margin-left: 5px;
          }
        }
        .bn-additional-slide-box__state {
          color: $module-clr__additionals-list-view--border;
          font-size: 2.5em;
          position: absolute;
          right: 0;
          text-align: right;
          top: 50%;
          transform: translateY(-50%);
          width: 40px;
          z-index: 5;
          .bn-icon {
            display: none;
          }
        }
        &:hover {
          border-bottom-color: rgba($module-clr__additionals-list-view--border, 0.5);
          .bn-additional-slide-box__headline {
            color: rgba($module-clr__additionals-list-view--headline, 0.5);
          }
          .bn-additional-slide-box__duration,
          .bn-additional-slide-box__amount {
            color: rgba($module-clr__additionals-list-view--text, 0.5);
          }
          .bn-additional-slide-box__state {
            color: rgba($module-clr__additionals-list-view--border, 0.5);
          }
        }
      }
      .bn-additional-slide-box__content {
        border-bottom: 1px solid $module-clr__additionals-list-view--border;
        padding: 20px 0;
        .bn-additional-slide-box__text {
          .bn-additional-slide-box__subtitle,
          .bn-additional-slide-box__description {
            @include typo--rte($module-clr__additionals-list-view--text,$module-clr__additionals-list-view--accent);
            font-size: $typo__fs--text;
            * {
              font-size: 1.0em !important;
            }
            table {
              width: 100%;
              td:first-child {
                width: 74%!important;
              }
            }
          }
          .bn-additional-slide-box__subtitle {
            + .bn-additional-slide-box__description {
              margin-top: 20px;
            }
          }
        }
        .bn-additional-slide-box__images {
          .bn-additional-slide-box__image {
            display: block;
            margin-top: 20px;
            img {
              @include img--responsive();
            }
            > div {
              overflow: hidden;
            }
            &:first-child {
              margin-top: 0;
            }
          }
        }
        .bn-additional-slide-box__button {
          padding-top: 20px;
          text-align: right;
          .bn-button {
            @include button--default();
          }
        }
      }
      // with images
      &.bn-additional-slide-box--pictorial {
        .bn-additional-slide-box__content {
          .bn-additional-slide-box__text {
            padding-left: 20px;
            order: 2;
            + .bn-additional-slide-box__images {
              max-width: 25%;
              order: 1;
            }
          }
        }
      }
      // accordion
      &.bn-accordion {
        .bn-additional-slide-box__head {
          &.bn-accordion__trigger {
            padding-right: 40px;
            .bn-additional-slide-box__state {
              .bn-icon {
                &.bn-icon--arrow-bottom {
                  display: inline-block;
                }
              }
            }
            &.bn-accordion__trigger--act {
              .bn-additional-slide-box__state {
                .bn-icon {
                  &.bn-icon--arrow-bottom {
                    display: none;
                  }
                  &.bn-icon--close {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
        .bn-additional-slide-box__content {
          &.bn-accordion__slide {
            display: none;
            overflow: hidden;
            &.bn-accordion__slide--open {
              display: block;
            }
          }
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-additionals-list-view {
    .bn-additionals-list-view__holder {
      .bn-additional-slide-box {
        .bn-additional-slide-box__head {
          display: block;
          .bn-additional-slide-box__headline,
          .bn-additional-slide-box__duration,
          .bn-additional-slide-box__amount {
            display: block;
            width: 100%;
          }
          .bn-additional-slide-box__duration,
          .bn-additional-slide-box__amount {
            padding-left: 0;
          }
        }
        .bn-additional-slide-box__content {
          .bn-additional-slide-box__description {
            table {
              td:first-child {
                width: 80% !important;
              }
            }
          }
        }
        // with images
        &.bn-additional-slide-box--pictorial {
          .bn-additional-slide-box__content {
            > .bn-grid__row {
              display: block;
            }
            .bn-additional-slide-box__text {
              padding-left: 0;
              order: 1;
              + .bn-additional-slide-box__images {
                margin-top: 20px;
                max-width: 100%;
                order: 2;
                .bn-additional-slide-box__image {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
