/*  ==========================================================================
    QUICK REQUEST V1
    ========================================================================== */

@mixin quick-request--v1() {
  background-color: $clr__ci--primary;
  bottom: 0;
  height: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: $unit__zi--quick-request;
  transition: all .5s;
  > .bn-grid__row {
    height: 100%;
  }
  .bn-quick-request__title,
  .bn-quick-request__form {
    align-items: center;
    display: flex;
  }
  .bn-quick-request__title {
    color: #fff;
    //display: none;
    font-size: 2.0em;
    max-width: 15%;
    text-transform: uppercase;
    > div {
      width: 100%;
    }
  }
  .bn-quick-request__form {
    //@include quick-request--horizontal();
  }
  &.bn-quick-request--small {
    width: calc(100% - #{$unit__wdt--header-v3-sidebar-desktop-large});
    .bn-quick-request__title {
      display: none;
    }
  }
  &.bn-quick-request--visible {
    border-top: 1px solid $clr__ci--primary;
    height: $unit__hgt--quick-request-v1;
    overflow: visible;
  }
  .bn_quickrequest {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .bn-form__column {
      position: relative;
      width: 13%;
      height: 40px;
      color: $clr__typo--primary;
      padding-right: 12px;
      .bn-form__group {
        height: 100%;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 2px;
        > input {
          background-color: transparent;
          height: 100%;
          width: 100%;
          position: relative;
          z-index: 1;
          font-size: 1.6em;
          color: $clr__typo--primary;
          padding: 0 10px;
          border: none;
        }
        > label {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          font-size: 1.6em;
          transition: all .5s;
          cursor: pointer;
          color: $clr__typo--primary;
          &.active {
            top: -50%;
            transform: none;
            color: #fff;
            font-size: 1.4em;
          }
        }
        .bn-form__groupIcon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
          font-size: 2em;
          cursor: pointer;
        }
      }
      &.bn-form__column--children,
      &.bn-form__column--guests {
        width: 15%;
        .bn-form__group {
          > label {
            left: 30px;
          }
          .bn-form__groupIcon {
            z-index: 1;
            .bn-iconBtn {
              border: 1px solid $clr__typo--primary;
              margin-left: 4px;
              cursor: pointer;
              //font-size: 1.5em;
              &:hover {
                background-color: $clr__typo--primary;
                border-color: #fff;
                color: #fff;
              }
            }
          }
          .childrenTemplate {
            position: absolute;
            bottom: calc(100% + 20px);
            left: 0;
            .bn-childrenContainer {
              background-color: $clr__ci--primary;
              padding: 10px;
              .bn-form__select-wrapper {
                position: relative;
                margin-top: 25px;
                background-color: $clr__ci--primary;
                border: none;
                .bn-from__select {
                  min-width: 140px;
                  background-color: $clr__ci--primary;
                  color: #fff;
                  font-size: 1.6em;
                  border: none;
                  border-bottom: 1px solid #fff;
                }
                label {
                  position: absolute;
                  left: 0;
                  top: -10px;
                  color: #fff;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      &.bn-form__column--consent {
        width: 10%;
        .bn-form__group {
          background-color: transparent;
          color: #fff;
          font-size: 1.4em;
          border: none;
          .bn-form__checkbox {
            input {
              display: none;
            }
            .bn-form__label {
              display: flex;
              align-items: center;
              padding: 0;
              position: relative;
              left: auto;
              top: auto !important;
              cursor: pointer;
              .bn-form__helper {
                position: relative;
                left: 0;
                top: -1px;
                border: 1px solid #fff;
                border-radius: 4px;
                transition: border-color .28s ease;
                padding: 0 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                min-width: 16px;
                height: 16px;
                .bn-form_checkIcon {
                  padding: 0 !important;
                  font-size: 10px;
                  display: none;
                  text-align: center;
                }
                &.active {
                  background-color: #fff;
                  .bn-form_checkIcon {
                    color: $clr__ci--primary;
                    display: block;
                  }
                }
                &:hover {

                }
              }
              .checkboxLabel {
                font-size: 12px;
                line-height: 16px;
                position: relative;
                cursor: pointer;
                padding-left: 10px;
                text-align: left;
                display: block;
              }
            }
            .consentMarketing {
              display: none;
              max-width: 500px;
              min-width: 325px;
              width: 100%;
              position: absolute;
              left: 0;
              z-index: 5;
              bottom: 100%;
              color: $clr__typo--primary;
              .consent_text {
                font-size: 10px;
                line-height: 15px;
                padding: 18px 20px;
                margin-bottom: 18px;
                position: relative;
                box-shadow: 2px 2px 28px 8px hsla(0,0%,62%,.25);
                background-color: #fff;
                &:after {
                  content: "";
                  left: 43px;
                  margin-left: -10px;
                  position: absolute;
                  top: 100%;
                  border-style: solid;
                  border-width: 15px 10px 0px 0px;
                  border-color: #fff transparent transparent transparent;
                }
              }
              .bn-closeContent {
                position: absolute;
                top: 0;
                right: 0;
                padding: 7px 7px 7px 10px;
                cursor: pointer;
                font-size: 10px;
              }
            }
            &:hover {
              .consentMarketing {
                display: block;
              }
            }
          }
        }
      }
      &.bn-quick-request--link {
        background-color: #DDA741;
        color: #fff;
        font-size: 1.6em;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        padding-right: 0;
        &:hover {
          background-color: rgba(#DDA741, .7);
        }
        &.bn-quick-request--request {
          background-color: #B98627;
          &:hover {
            background-color: rgba(#B98627, .7);
          }
        }
      }
    }
    .bn-form__column--guests {
      min-width: 180px;
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // desktop medium
  @media #{$mq__desktop--medium} {
    &.bn-quick-request--small {
      width: calc(100% - #{$unit__wdt--header-v3-sidebar});
    }
  }
  // laptop
  @media #{$mq__laptop} {
    .bn-quick-request__title {
      display: none;
    }
    .bn_quickrequest .bn-form__column.bn-form__column--consent {
      width: 14%;
    }
  }
  // tablet
  @media #{$mq__tablet} {
    display: none;
  }
}