/*  ==========================================================================
    MOBILE HEADER V1
    ========================================================================== */

@mixin mobile-header--v1() {
  background-color: rgba(#B4AA98, .9);
  //border-bottom: 1px solid $clr__ci--primary;
  height: $unit__hgt--mobile-header-v1;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $unit__zi--mobile-header;
  color: $clr__typo--secondary;
  // controls
  .bn-controls {
    height: 100%;
    > .bn-grid__row {
      height: 100%;
      justify-content: space-between;
      .bn-controls__left,
      .bn-controls__middle,
      .bn-controls__right {
        flex: none;
      }
      .bn-controls__right .bn-tel {
        a {
          color: $clr__typo--secondary;
          padding: 0 5px;
          &:visited {
            color: $clr__typo--secondary;
          }
          &:hover {
            color: $clr__ci--primary;
          }
        }
        .bn-icon {
          font-size: 2.6em;
          //color: $clr__white
        }
      }
    }
    .bn-controls__left {
      .bn-controls__left___top,
      .bn-controls__left___bottom {
        > .bn-grid__col {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
        }
      }
      .bn-controls__left___top {
        height: 30%;
        > .bn-grid__col {
          padding-top: 10px;
        }
      }
      .bn-controls__left___bottom {
        height: 60%;
        > .bn-grid__col {
          justify-content: flex-end;
          padding-bottom: 10px;
        }
      }
    }
    .bn-controls__right,
    .bn-controls__middle {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--above {
      display: none;
      ul {
        @include clearfix();
        font-size: 1.6em;
        list-style-type: none;
        margin-left: -5px;
        padding-left: 0;
        li {
          float: left;
          &:before {
            content: '|';
          }
          a {
            color: $clr__typo--secondary;
            padding: 0 5px;
            &:visited {
              color: $clr__typo--secondary;
            }
            &:hover {
              color: $clr__ci--primary;
            }
          }
          &.bn-lang-menu__item--act {
            a {
              font-weight: $typo__fw--bold;
            }
          }
          &:first-child {
            border-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  // burger menu
  .bn-burger-menu {
    cursor: pointer;
    display: inline-block;
    font-size: 2.5em;
    &:hover {
      color: $clr__ci--primary;
    }
  }
  // control bar
  .bn-control-bar {
    @include mobile-header--control-bar();
  }
  // main logo
  .bn-main-logo {
    &.bn-main-logo--above {
      display: inline-block;
      .bn-main-logo__image {
        img {
          @include img--rendering-crisp-edges();
          max-height: 90px;
          max-width: 230px;
        }
        &.bn-main-logo__image--big,
        &.bn-main-logo__image--big--novista,
        &.bn-main-logo__image--small {
          display: none;
        }
      }
    }
  }
  // minimized
  &.bn-mobile-header--minimized {
    background-color: transparent;
    border-width: 0;
    height: 0;
    // controls
    .bn-controls {
      .bn-controls__left {
        .bn-controls__left___top {
          display: none;
        }
        .bn-controls__left___bottom {
          height: auto;
          > .bn-grid__col {
            padding-bottom: 0;
          }
        }
      }
      .bn-controls__right,
      .bn-controls__middle {
        display: none;
      }
    }
    // burger menu
    .bn-burger-menu {
      background-color: $clr__gray;
      border: 1px solid $clr__ci--primary;
      border-left-color: transparent;
      border-top-color: transparent;
      color: $clr__typo--secondary;
      font-size: 1.8em;
      height: $unit__hgt--mobile-burger-menu;
      left: 0;
      line-height: calc(#{$unit__hgt--mobile-burger-menu} - 2px);
      position: absolute;
      text-align: center;
      top: 0;
      width: $unit__hgt--mobile-burger-menu;
      &:hover {
        color: $clr__ci--primary;
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // phone
  @media #{$mq__phone} {
    height: $unit__hgt--mobile-header-v1-phone;
    // controls
    .bn-controls {
      .bn-controls__left {
        .bn-controls__left___top {
          display: none;
        }
        .bn-controls__left___bottom {
          height: 100%;
          > .bn-grid__col {
            justify-content: center;
            padding-bottom: 0;
          }
        }
      }
    }
    // main logo
    .bn-main-logo {
      &.bn-main-logo--above {
        .bn-main-logo__image {
          img {
            max-height: 60px;
            max-width: 180px;
          }
        }
      }
    }

    .bn-grid .bn-grid__row .bn-grid__col.bn-grid__spacing--m, .bn-grid .bn-grid__row.bn-grid__spacing--m {
      padding: 20px;
    }
  }
}
