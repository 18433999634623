/*  ==========================================================================
    LINKBOXES
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__linkboxes--color: $clr__typo--secondary;
$module-clr__linkboxes--bg: $clr__ci--primary;

/*
 * MODULE
 */

.bn-linkboxes {
  .bn-linkboxes__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .bn-linkboxes__item {
      display: inline-block;
      position: relative;
      width: calc(25% - 10px);
      margin-bottom: 20px;
      .bn-linkboxes__image {
        display: block;
        > div {
          overflow: hidden;
        }
        img {
          @include img--responsive();
        }
      }
      .bn-linkboxes__titles {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 100%;
        background: rgba($clr__black, 0.1);
        transition: background .2s;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        .bn-linkboxes__title {
          span {
            font-size: 2.5em;
            color: $clr__typo--secondary;
            text-transform: uppercase;
            padding: 0 10px;
          }
        }
        .bn-linkboxes__subtitle {
          span {
            font-size: 2em;
            color: $clr__typo--secondary;
            text-transform: uppercase;
            padding: 0 10px;
          }
        }
      }
      &:hover {
        .bn-linkboxes__titles {
          background: rgba($clr__black, 0.4);
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// laptop
@media #{$mq__laptop} {
  .bn-linkboxes {
    .bn-linkboxes__list {
      .bn-linkboxes__item {
        .bn-linkboxes__titles {
          .bn-linkboxes__title {
            span {
              font-size: 2em;
            }
          }
          .bn-linkboxes__subtitle {
            span {
              font-size: 1.6em;
            }
          }
        }
      }
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-linkboxes {
    .bn-linkboxes__list {
      .bn-linkboxes__item {
        width: calc(50% - 10px);
      }
    }
  }
}

// phablet
@media #{$mq__phone} {
  .bn-linkboxes {
    .bn-linkboxes__list {
      .bn-linkboxes__item {
        width: 100%;
      }
    }
  }
}

// small phone
@media #{$mq__phone--small} {
  .bn-linkboxes {
    .bn-linkboxes__list {
      .bn-linkboxes__item {
        .bn-linkboxes__titles {
          .bn-linkboxes__subtitle {
            display: none;
          }
        }
      }
    }
  }
}
