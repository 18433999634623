/*  ==========================================================================
    PRICE LISTS
    ========================================================================== */

// price list v1
@mixin price-list--v1($clr,$clr-accent) {
  .bn-price-list {
    .bn-price-list__tabs {
      .bn-price-list__tab {
        display: inline-block;
        padding: 0 10px 20px 0;
        .bn-button {
          @include button--default-inverted();
          border-radius: 0;
          height: auto;
          min-width: auto;
          line-height: normal;
          padding: 5px 25px;
          &.bn-tab-panel__tab--act {
            background-color: $clr__ci--primary !important;
            border-color: $clr__ci--primary !important;
            color: $clr__typo--secondary !important;
            cursor: default;
            border-radius: 0;
            height: auto;
            line-height: normal;
            min-width: auto;
            padding: 5px 25px;
          }
        }
      }
    }
    .bn-price-list__panels {
      .bn-price-list__panel {
        display: none;
        .bn-table-wrap {
          @include typo--table-wrap($clr,$clr-accent);
          table {
            table-layout: fixed;
            tbody {
              tr {
                td {
                  text-align: center;

                  &:first-child {
                    text-align: left;
                  }
                }
              }
            }
          }
        }
        &.bn-tab-panel__panel--act {
          display: block;
        }
      }
    }
    // show only one price list (only fallback to make sure in case a second one gets loaded)
    + .bn-price-list {
      display: none !important;
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // phablet
  @media #{$mq__phablet} {
    .bn-price-list {
      .bn-price-list__tabs {
        .bn-price-list__tab {
          display: block;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    .bn-price-list {
      .bn-price-list__tabs {
        margin-bottom: 10px;
        .bn-price-list__tab {
          padding-bottom: 10px;
        }
      }
    }
  }
}

// price list v2
@mixin price-list--v2() {
  position: relative;
  .bn-price-list {
    .bn-price-list__top,
    .bn-price-list__bottom {
      align-items: stretch;
      flex-direction: row;
      font-size: 1.8em;
      .bn-price-list__info,
      .bn-price-list__seasondatas,
      .bn-price-list__rates {
        flex: 1;
      }
      .bn-price-list__seasondatas,
      .bn-price-list__rates {
        align-items: stretch;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        .bn-price-list__seasondata,
        .bn-price-list__rate {
          flex: 1;
          padding: 5px 10px;
        }
        &.slick-slider {
          .slick-list {
            padding-right: 40px;
          }
        }
      }
      .bn-price-list__info {
        flex: 0 0 25%;
        padding: 5px 10px;
      }
    }
    .bn-price-list__top {
      background-color: $clr__ci--primary;
      color: $clr__typo--secondary;
      display: none;
      .bn-price-list__seasondatas {
        .bn-price-list__seasondata {
          border-left: 2px solid $clr__white;
        }
      }
    }
    .bn-price-list__center {
      align-items: center;
      background-color: rgba($clr__ci--primary,0.1);
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;
      .bn-price-list__headline {
        @include typo--subheadline($clr__typo--primary);
        a {
          color: $clr__typo--primary;
          &:visited,
          &:hover {
            color: $clr__typo--primary;
          }
        }
      }
      .bn-price-list__arrows {
        text-align: right;
        white-space: nowrap;
        width: 50px;
        .bn-arrows__arrow {
          display: inline-block !important;
          font-size: 2.0em;
          cursor: pointer;
          &.slick-disabled {
            cursor: default;
            opacity: 0.3;
            pointer-events: none;
          }
          &:not(.slick-disabled) {
            &:hover {
              color: $clr__ci--primary;
            }
          }
          // hide duplicated arrows
          &.bn-arrows__arrow-prev {
            + .bn-arrows__arrow-prev {
              display: none !important;
            }
          }
          &.bn-arrows__arrow-next {
            margin-left: 5px;
            + .bn-arrows__arrow-next {
              display: none !important;
            }
          }
        }
      }
    }
    .bn-price-list__bottom {
      border-left: 2px solid rgba($clr__ci--primary,0.1);
      border-right: 2px solid rgba($clr__ci--primary,0.1);
      display: flex;
      .bn-price-list__info {
        white-space: nowrap;
      }
      .bn-price-list__rates {
        .bn-price-list__rate {
          border-left: 2px solid rgba($clr__ci--primary,0.1);
          white-space: nowrap;
          &:hover {
            background-color: rgba($clr__typo--primary,0.2);
          }
        }
      }
    }
  }
  // sticky seasondatas
  &.bn-room-rates--sticky {
    @include clearfix();
    position: static;
    position: sticky;
    top: 0;
    z-index: 5;
    .bn-price-list {
      .bn-price-list__top {
        display: flex;
      }
      .bn-price-list__center,
      .bn-price-list__bottom {
        display: none;
      }
    }
    // offset for sticky seasondatas (only needed if specific header types are set!)
    &.bn-room-rates--header-v1,
    &.bn-room-rates--header-v2 {
      top: $unit__hgt--header-v1-minimized;
    }
  }
  &:last-child {
    .bn-price-list {
      border-bottom: 2px solid rgba($clr__ci--primary,0.1);
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // full screen off
  @media #{$mq__full-screen--off} {
    // sticky seasondatas
    &.bn-room-rates--sticky {
      // renmove offset for sticky seasondatas if full screen is not given (only needed if specific header types are set!)
      &.bn-room-rates--header-v1,
      &.bn-room-rates--header-v2 {
        top: 0;
      }
    }
  }
  // tablet
  @media #{$mq__tablet} {
    .bn-price-list {
      .bn-price-list__top,
      .bn-price-list__bottom {
        .bn-price-list__info {
          flex-basis: 30%;
        }
      }
    }
    // sticky seasondatas
    &.bn-room-rates--sticky {
      top: 0;
      .bn-price-list {
        .bn-price-list__top {
          align-items: center;
          height: $unit__hgt--mobile-burger-menu;
        }
      }
      // offset for sticky seasondatas (only needed if specific header types are set!)
      &.bn-room-rates--header-v1,
      &.bn-room-rates--header-v2 {
        top: 0;
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    .bn-price-list {
      .bn-price-list__top,
      .bn-price-list__bottom {
        font-size: 1.6em;
        .bn-price-list__info {
          flex-basis: 40%;
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    .bn-price-list {
      .bn-price-list__top,
      .bn-price-list__bottom {
        .bn-price-list__seasondatas,
        .bn-price-list__rates {
          &.slick-slider {
            .slick-list {
              padding-right: 20px;
            }
          }
        }
        .bn-price-list__info {
          flex-basis: 50%;
        }
      }
    }
  }
}
