/*  ==========================================================================
    REQUEST V1
    ========================================================================== */

@mixin request--v1() {

  // ...

  /*
   * RESPONSIVE BEHAVIOR
   */

  // ...

}