/*  ==========================================================================
    MISC
    Styling for various unclassifiable things which are mostly at the bottom
    of the page, after the footer.
    ========================================================================== */

// scroll up
.bn-scroll-up {
  background-color: #E3E3E3;
  border: 1px solid #D1D1D1;
  border-radius: 100%;
  color: #626262;
  cursor: pointer;
  display: none;
  font-size: 3em;
  height: 62px;
  line-height: 48px;
  position: fixed;
  right: -100%;
  bottom: 165px;
  text-align: center;
  transform: translateY(-50%);
  visibility: hidden;
  width: 62px;
  z-index: $unit__zi--scroll-up;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: $clr__ci--primary;
    color: #fff;
  }
  &.bn-scroll-up--activated {
    display: flex;
    &.bn-scroll-up--visible {
      right: 80px;
      visibility: visible;
    }
  }
}
// waypoint 1
.bn-waypoint {
  &.bn-waypoint--1 {
    bottom: 0;
    left: 0;
    position: absolute;
    top: 200px;
  }
  &.bn-waypoint--3 {
    height: 0px;
  }
}
.bn-request-mobile {
  display: none;
}
.bn-info--right {
  position: absolute;
  bottom: 60px;
  right: 0;
  width: 100%;
  pointer-events: none;
  z-index: 5;
  .bn-info--right__wrap {
    text-align: right;
    .bn-info--right__icon {
      display: inline-block;
      pointer-events: auto;
      .bn-icon {
        font-size: 32px;
      }
    }
  }
  &.bn-info--right__whatsapp {
    position: fixed;
    bottom: 120px;
    z-index: 31;
    pointer-events: none;
    .bn-whatsapp {
      pointer-events: auto;
      position: relative;
      .bn-icon {
        font-size: 62px;
        position: relative;
        z-index: 1;
      }
      .bn-whatsapp--background {
        display: block;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        border: 1px solid #fff;
        border-radius: 100%;
        transform: translateX(-50%)translateY(-50%);
      }
    }
  }
}
.bn-vista__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: inset 0 0 350px 10px rgba(25,25,25,.75);
  box-shadow: inset 0 0 350px 10px rgba(25,25,25,.75);
  pointer-events: none;
}
//.bn-tour {
//  position: absolute;
//  top: 150px;
//  right: 0;
//  width: 100%;
//  pointer-events: none;
//  z-index: 5;
//  .bn-tour__wrap {
//    text-align: right;
//    .bn-tour__link {
//      position: relative;
//      display: inline-block;
//      pointer-events: auto;
//      .bn-icon {
//        font-size: 62px;
//        position: relative;
//        z-index: 1;
//      }
//      .bn-tour--background {
//        display: block;
//        width: calc(100% - 4px);
//        height: calc(100% - 4px);
//        background: rgba(#000, .1);
//        position: absolute;
//        top: 50%;
//        left: 50%;
//        border: 1px solid rgba(#000, .1);
//        border-radius: 100%;
//        transform: translateX(-50%)translateY(-50%);
//      }
//    }
//  }
//}
.bn-bestprice {
  text-transform: uppercase;
  position: absolute;
  bottom: 100px;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 5;
  .bn-bestprice__wrap {
    text-align: left;
    .bn-bestprice__link {
      position: relative;
      pointer-events: auto;
      width: 260px;
      height: 110px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $clr__ci--primary;
      border: 1px solid $clr__ci--primary;
      border-radius: 4px;
      text-align: left;
      letter-spacing: 1px;
      .bn-bestprice__link--wrap {
        .bn-bestprice__title {
          font-size: 21px;
          font-weight: 700;
        }
        .bn-bestprice__subtitle {
          font-size: 21px;
        }
        .bn-bestprice__stars {
          margin-bottom: 3px;
          .bn-icon {
            font-size: 14px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
.flatpickr-day.hidden {
  display: block !important;
  visibility: visible !important;
}
// quick request
.bn-quick-request {
  visibility: visible !important;
  &.bn-quick-request--v1 {
    @include quick-request--v1();
  }
  &.bn-quick-request--v2 {
    //@include quick-request--v2();
  }
}
body {
  &.header-v4 {
    .bn-quick-request {
      &.bn-quick-request--v1 {
        //@include quick-request--v1();
      }
    }
  }
}
// ehotelier pop up
.bn-ehotelier-pop-up {
  bottom: 0;
  height: 100%;
  left: 0;
  max-height: 0;
  overflow: hidden;
  position: fixed;
  transition: max-height 0.5s;
  transition-delay: 0.5s;
  width: 100%;
  z-index: $unit__zi--pop-up;
  > .bn-grid {
    padding: 40px 40px 0 40px;
  }
  .bn-ehotelier-pop-up__overlay {
    background-color: transparent;
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color 0.5s;
    transition-delay: 0s;
    width: 100%;
    z-index: 5;
  }
  .bn-ehotelier-pop-up__paper {
    background-color: $clr__white;
    box-shadow: 0 0 2px 0 rgba($clr__black,0.4);
    height: calc(100vh - 40px);
    position: relative;
    z-index: 10;
    > div {
      background-color: rgba($clr__ci--primary,0.2);
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 40px 40px 0 40px;
    }
    .bn-ehotelier-pop-up__head {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 50px;
      line-height: 50px;
      margin-bottom: 40px;
      white-space: nowrap;
      .bn-ehotelier-pop-up__close {
        color: $clr__ci--primary;
        cursor: pointer;
        display: inline-block;
        font-size: 3.5em;
        width: 50px;
        &:hover {
          color: $clr__ci--secondary;
        }
      }
      .bn-ehotelier-pop-up__controls {
        @include clearfix();
        //width: calc(100% - 50px);
        display: flex;
        flex-direction: row;
        .bn-ehotelier-pop-up__arrows {
          @include slider--arrows($clr__ci--primary);
          display: inline-block;
          float: right;
          .bn-arrows__arrow {
            font-size: 3.5em;
            .bn-icon {
              font-size: 1.0em;
            }
            &.bn-arrows__arrow-next {
              margin-left: 10px;
            }
          }
        }
        .bn-ehotelier-pop-up__control-info {
          color: $clr__ci--primary;
          display: inline-block;
          float: right;
          font-size: 1.6em;
          margin-left: 10px;
        }
      }
    }
    .bn-ehotelier-pop-up__holder {
      @include offers--detail($clr__ci--primary,$clr__ci--primary,$clr__typo--primary,$clr__typo--primary,$clr__typo--primary,$clr__typo--secondary);
      @include rooms--detail($clr__ci--primary,$clr__ci--primary,$clr__typo--primary,$clr__typo--primary,$clr__typo--primary,$clr__typo--secondary,$clr__ci--secondary,$clr__typo--secondary);
      @include scrollbar($clr__scrollbar,5px);
      max-height: 100%;
      overflow-y: auto;
      .bn-offer-box,
      .bn-room-box {
        padding-bottom: 40px;
      }
    }
  }
  &.bn-ehotelier-pop-up--open {
    max-height: 100%;
    transition-delay: 0s;
    .bn-ehotelier-pop-up__overlay {
      background-color: rgba($clr__black,0.8);
      transition-delay: 0.5s;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-request-mobile {
    display: block;
    z-index: 30;
    .bn-request-mobile__wrap {
      display: flex;
      .bn-request-mobile__button {
        width: calc(50% - 28px);
        height: 60px;
        background-color: $clr__ci--primary;
        text-transform: uppercase;
        font-size: 16px;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        > a {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        * {
          color: $clr__white;
        }
        &.bn-request-mobile__request {
          background-color: $clr__cta--primary;
        }
      }
      .bn-request-mobile__whatsapp {
        width: 56px;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
        .bn-whatsapp {
          pointer-events: auto;
          position: relative;
          display: block;
          background: #4cc247;
          .bn-icon {
            font-size: 56px;
            position: relative;
            z-index: 1;
          }
          .bn-whatsapp--background {
            display: block;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            border: 1px solid #fff;
            border-radius: 100%;
            transform: translateX(-50%)translateY(-50%);
          }
        }
      }
    }
    &.js-active {
      box-shadow: 0 2px 10px 0 #B3ACAA;
      position: fixed;
      top: 80px;
      left: 0;
      right: 0;
      width: 100%;
    }
  }
  .bn-info--right {
    bottom: 185px;
    .bn-info--right__wrap {
      text-align: left;
    }
    &.bn-info--right__whatsapp {
      bottom: 120px;
      top: auto;
      display: none;
      .bn-info--right__wrap {
        text-align: right;
      }
    }
  }
  .bn-tour {
    top: 30px;
  }
  .bn-bestprice {
    bottom: 60px;
  }
  // scroll up
  .bn-scroll-up {
    bottom: 40px;
    font-size: 3.0em;
    left: -100%;
    right: auto;
    top: auto;
    transform: none;
    &.bn-scroll-up--activated {
      &.bn-scroll-up--visible {
        left: 40px;
        right: auto;
      }
    }
  }
  // ehotelier pop up
  .bn-ehotelier-pop-up {
    .bn-ehotelier-pop-up__paper {
      > div {
        padding-top: 20px;
      }
      .bn-ehotelier-pop-up__head {
        margin-bottom: 20px;
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-info--right {
    bottom: 40px;
    .bn-info--right__wrap .bn-info--right__icon .bn-icon {
      font-size: 26px;
    }
    &.bn-info--right__whatsapp {
      bottom: 20px;
      top: auto;
      .bn-whatsapp .bn-icon {
        font-size: 60px;
      }
    }
  }
  .bn-tour {
    top: 30px;
    .bn-tour__wrap .bn-tour__link .bn-icon {
      font-size: 52px;
    }
  }
  .bn-bestprice {
    display: none;
    bottom: 40px;
    .bn-bestprice__wrap {
      .bn-bestprice__link {
        width: 170px;
        height: 80px;
        .bn-bestprice__link--wrap {
          .bn-bestprice__title {
            font-size: 13px;
          }
          .bn-bestprice__subtitle {
            font-size: 13px;
          }
          .bn-bestprice__stars {
            .bn-icon {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  // scroll up
  .bn-scroll-up {
    bottom: 20px;
    &.bn-scroll-up--activated {
      &.bn-scroll-up--visible {
        left: 20px;
      }
    }
  }
  // ehotelier pop up
  .bn-ehotelier-pop-up {
    .bn-ehotelier-pop-up__paper {
      > div {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-request-mobile {
    &.js-active {
      top: 90px;
    }
  }
  // ehotelier pop up
  .bn-ehotelier-pop-up {
    > .bn-grid {
      padding: 20px 20px 0 20px;
    }
    .bn-ehotelier-pop-up__paper {
      .bn-ehotelier-pop-up__head {
        .bn-ehotelier-pop-up__controls {
          .bn-ehotelier-pop-up__control-info {
            display: none;
          }
        }
      }
    }
  }
}

// custom
@media (max-width: 360px) {
  .bn-bestprice {
    display: none;
  }
}
